* {
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  /* display: flex; */
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  /* height: 100%;
  justify-content: space-between; */
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (min-width: 0px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 4px;
  }
  /* 
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  } */
  .nav-width {
    height: 90px;
    background-color: #212121;
  }
  .menu-icon {
    display: block;
    text-align: right;

    cursor: pointer;
  }

  .menu-icon2 {
    display: block;
    text-align: right;

    cursor: pointer;
  }

  .menu-icon3 {
    display: block;

    cursor: pointer;
  }

  .menu-icon4 {
    display: block;
    text-align: right;

    cursor: pointer;
  }

  /* .menu-icon2 {
    display: block;
    position: absolute;
    top: 0;
    right: 2rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .menu-icon3 {
    display: block;
    position: absolute;
    top: 0;
    right: 4rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  .menu-icon4 {
    display: block;
    position: absolute;
    top: 0;
    right: 6rem;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  } */

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 999;
    right: 20px;
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    margin-right: 30px;
  }

  .dropdown-content a:hover {
    background-color: #4a4646;
    color: #f9f9f9;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
}
