.building-table {
  tbody {
    tr:hover {
      z-index: 9999;
      border: solid 4px #1888ff;
      cursor: pointer;
      td {
        background-color: #bbdefb !important;
      }
    }
  }
}

.container-layout {
  display: flex;
  justify-content: space-between;

}

.container-layout::after {
  content: '';
  flex: 0;
}


.pagination {
  display: flex;
  margin: 20px 0; /* Margin top & bottom */
  justify-content: center;
  button {
    border: 1px solid #ccc;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0 5px; /* Margin left & right */
    cursor: pointer;
  }
  button:disabled {
    background-color: #e0e0e0; /* Grey background for disabled buttons */
    color: #a0a0a0; /* Grey text for disabled buttons */
    cursor: default;
    border: 1px solid #d0d0d0;
  }
}
