@tailwind base;
@tailwind components;
@tailwind utilities;

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
