.home-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 80px;
  opacity: 1;
  transition: all 0.5s ease;
  background: #242222;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}
